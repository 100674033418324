<template>
  <n-card :bordered="false" content-style="padding-left: unset;padding-right: unset;">
    <List :options="options" :deleteFunction="deleteFunction" />
    <template #footer
      ><n-button @click="addingNewAddress = true">
        <template #icon>
          <n-icon>
            <new-item-icon :color="themeOverrides.common.primaryColor" />
          </n-icon>
        </template>
        {{ t("commons.actions.add", { referece: t("commons.labels.address", 2) }) }}
      </n-button>
    </template>
  </n-card>

  <n-modal v-model:show="addingNewAddress" preset="dialog" :show-icon="false">
    <div>
      <n-h3 class="my-1 pb-2">{{ t("locationDialog.title") }}</n-h3>
      <location-finder v-model="newLocation" />
      <n-divider style="margin: 5px 0px" />
      <n-space justify="end">
        <n-button @click="addingNewAddress = false">{{
          t("commons.actions.cancel")
        }}</n-button>
        <n-button
          @click="addNewAddress"
          type="primary"
          :disabled="!newLocation.address || !newLocation.postalCode"
          >{{ t("commons.actions.add") }}</n-button
        >
      </n-space>
    </div>
  </n-modal>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";
import List from "@/components/List.vue";
import { NDivider, NCard, NButton, NIcon, NModal, NH3, NSpace } from "naive-ui";
import LocationFinder from "@/components/LocationFinder.vue";
import { AddOutline as NewItemIcon } from "@vicons/ionicons5";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Addresses",
  components: {
    List,
    LocationFinder,
    NewItemIcon,
    NDivider,

    NCard,
    NButton,
    NIcon,
    NModal,
    NH3,
    NSpace,
  },
  props: {
    value: { type: Object },
    currentAddressInPayout: { type: String },
  },
  emits: ["update:value"],
  data() {
    return {
      themeOverrides,
    };
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const addressesRef = ref(props.value);
    const addingNewAddressRef = ref(false);

    const deleteFunction = (option) => {
      addressesRef.value = addressesRef.value.filter(
        (address) => address.id != option.value
      );
    };

    const newLocationRef = ref({
      address: null,
      coordinates: null,
      availabilityRatio: 5,
      municipality: null,
      region: null,
    });
    const optionsRef = computed(() => {
      const options = addressesRef.value?.map((address) => {
        return {
          value: address.id,
          label: `${address.locationInfo.address}, ${address.locationInfo.municipality}, ${address.locationInfo.region}`,
          options: {
            hideDeleteButton: address.id === props.currentAddressInPayout,
          },
        };
      });
      return options;
    });

    const addNewAddress = async () => {
      const newAddress = {
        id:
          addressesRef.value.length === 0
            ? 1
            : (
                Math.max(...addressesRef.value.map((address) => parseInt(address.id))) + 1
              ).toString(),
        locationInfo: newLocationRef.value,
        useForPayout: false,
      };
      addressesRef.value = [...addressesRef.value, newAddress];
      addingNewAddressRef.value = false;
      newLocationRef.value = {
        address: null,
        coordinates: null,
        availabilityRatio: 5,
        municipality: null,
        region: null,
      };
    };

    watch(
      addressesRef,
      () => {
        emit("update:value", addressesRef.value);
      },
      { deep: true }
    );

    return {
      t,
      options: optionsRef,
      deleteFunction,
      addingNewAddress: addingNewAddressRef,
      newLocation: newLocationRef,
      addNewAddress,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(.n-card > .n-card-header) {
  padding-left: unset;
  padding-right: unset;
}

:deep(.n-card > .n-card__footer) {
  text-align: end;
  padding-right: unset;
}
</style>
