<template>
  <n-grid
    x-gap="12"
    y-gap="12"
    cols="1 s:10"
    responsive="screen"
    style="padding-top: 30px"
  >
    <n-gi span="4" offset="0 s:1">
      <n-card :title="t('profile.contactInformation.title')">
        <n-form
          ref="formRef"
          :model="user"
          :rules="rules"
          label-placement="left"
          require-mark-placement="right-hanging"
          label-width="auto"
        >
          <n-form-item
            :label="t('profile.contactInformation.form.email.label')"
            path="email"
          >
            <n-input
              v-model:value="user.email"
              :placeholder="t('profile.contactInformation.form.email.placeholder')"
              disabled
            />
          </n-form-item>
          <n-form-item
            :label="t('profile.contactInformation.form.firstName.label')"
            path="name"
          >
            <n-input
              v-model:value="user.name"
              :placeholder="t('profile.contactInformation.form.firstName.placeholder')"
            />
          </n-form-item>
          <n-form-item
            :label="t('profile.contactInformation.form.lastName.label')"
            path="familyName"
          >
            <n-input
              v-model:value="user.familyName"
              :placeholder="t('profile.contactInformation.form.lastName.placeholder')"
            />
          </n-form-item>
          <n-form-item
            :label="t('profile.contactInformation.form.phone.label')"
            path="phoneNumber"
          >
            <n-input
              v-model:value="user.phoneNumber"
              :placeholder="t('profile.contactInformation.form.phone.placeholder')"
            />
          </n-form-item> </n-form
        ><template #action>
          <div style="display: flex; justify-content: flex-end">
            <n-button type="primary" @click="updateContactInfo">
              {{ t("commons.actions.update") }}
            </n-button>
          </div>
        </template></n-card
      ></n-gi
    ><n-gi span="4">
      <n-card :title="t('profile.addresses.title')">
        <Addresses
          v-if="user.addresses"
          v-model:value="user.addresses"
          @update:value="updateAddresses"
          :currentAddressInPayout="currentAddressInPayout"
        />
        <template #action
          ><div style="display: flex; justify-content: flex-end">
            <n-button type="primary" @click="updateAddresses">
              {{ t("commons.actions.update") }}
            </n-button>
          </div></template
        ></n-card
      ></n-gi
    ><n-gi span="4" offset="0 s:1">
      <n-card :title="t('profile.password.title')">
        <n-form
          ref="formPasswordRef"
          :model="formPassword"
          :rules="rulesPassword"
          label-placement="left"
          require-mark-placement="right-hanging"
          label-width="auto"
        >
          <n-form-item
            :label="t('profile.password.form.currentPassword.label')"
            path="currentPassword"
          >
            <n-input
              :placeholder="t('profile.password.form.currentPassword.placeholder')"
              type="password"
              show-password-on="mousedown"
              v-model:value="formPassword.currentPassword"
            />
          </n-form-item>
          <n-form-item
            :label="t('profile.password.form.newPassword.label')"
            path="newPassword"
          >
            <n-input
              :placeholder="t('profile.password.form.newPassword.placeholder')"
              type="password"
              show-password-on="mousedown"
              v-model:value="formPassword.newPassword"
            />
          </n-form-item>
          <n-form-item
            :label="t('profile.password.form.repeatPassword.label')"
            path="repeatPassword"
          >
            <n-input
              :placeholder="t('profile.password.form.repeatPassword.placeholder')"
              type="password"
              show-password-on="mousedown"
              v-model:value="formPassword.repeatPassword"
            />
          </n-form-item>
        </n-form>
        <template #action>
          <div style="display: flex; justify-content: flex-end">
            <n-button type="primary" @click="changePassword">
              {{ t("commons.actions.change") }}
            </n-button>
          </div>
        </template></n-card
      ></n-gi
    ><n-gi span="4">
      <n-card :title="t('profile.payoutMethodsInformation.title')">
        <PayoutProfileUpdate
          v-model:value="user.payoutProfile"
          v-model:formRef="formRefPayoutProfileRef"
        />
        <template #action>
          <div style="display: flex; justify-content: flex-end">
            <n-button type="primary" @click="updatePayoutInfo">
              {{ t("commons.actions.update") }}
            </n-button>
          </div>
        </template></n-card
      ></n-gi
    ></n-grid
  >
</template>

<script>
import { defineComponent, computed, watch, ref, onMounted } from "vue";
import {
  NCard,
  NGrid,
  NGi,
  NForm,
  NFormItem,
  NInput,
  NButton,
  useMessage,
} from "naive-ui";
import { useStore } from "vuex";
import Addresses from "@/components/myProfile/Addresses.vue";
import { COUNTRY_CODE } from "@/shared/constants.js";
import { Auth } from "aws-amplify";
import PayoutProfileUpdate from "@/components/balance/PayoutProfileUpdate";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "MyProfile",
  components: {
    NCard,
    NGrid,
    NGi,
    NForm,
    NFormItem,
    NInput,
    NButton,
    Addresses,
    PayoutProfileUpdate,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const message = useMessage();
    const formRef = ref(null);
    const formPasswordRef = ref(null);
    const formRefPayoutProfileRef = ref(null);
    const userStoreRef = computed(() => store.state.user.userFromDB);
    const userRef = ref({});
    const formPassword = ref({
      currentPassword: null,
      newPassword: null,
      repeatPassword: null,
    });
    const currentAddressInPayoutRef = ref(null);
    onMounted(() => {
      userRef.value =
        {
          ...userStoreRef.value,
          phoneNumber: userStoreRef.value?.phoneNumber.replace(COUNTRY_CODE, ""),
        } || {};
      if (!currentAddressInPayoutRef.value && userRef.value?.payoutProfile?.address.id) {
        currentAddressInPayoutRef.value = userRef.value?.payoutProfile?.address.id;
      }
    });

    watch(
      userStoreRef,
      (newValue) => {
        userRef.value = {
          ...newValue,
          phoneNumber: newValue?.phoneNumber.replace(COUNTRY_CODE, ""),
        };
        if (
          !currentAddressInPayoutRef.value &&
          userRef.value?.payoutProfile?.address.id
        ) {
          currentAddressInPayoutRef.value = userRef.value?.payoutProfile?.address.id;
        }
      },
      { deep: true }
    );

    const validatePasswordStartWith = (rule, value) => {
      return (
        formPassword.value.newPassword &&
        formPassword.value.newPassword.startsWith(value) &&
        formPassword.value.newPassword.length >= value.length
      );
    };

    const validatePasswordSame = (rule, value) => {
      return value === formPassword.value.newPassword;
    };
    const validatePasswordDifferent = (rule, value) => {
      return value !== formPassword.value.currentPassword;
    };

    return {
      t,
      currentAddressInPayout: currentAddressInPayoutRef,
      formRef,
      formPasswordRef,
      formRefPayoutProfileRef,
      formPassword,
      user: userRef,
      userStoreRef,
      rules: {
        name: [
          {
            required: true,
            message: "Please input your first name",
            trigger: ["input"],
          },
        ],
        familyName: {
          required: true,
          message: "Please input your last name",
          trigger: ["input"],
        },
        phoneNumber: {
          required: true,
          message: "Please input your phone number",
          trigger: ["input"],
          pattern: "(?:^(?:(?:1?){1})([2-9]{1}\\d{2})([2-9]{1}\\d{2})(\\d{4}))$",
        },
      },
      rulesPassword: {
        currentPassword: {
          required: true,
          message: "Please input your current password",
          trigger: ["input"],
        },
        newPassword: [
          {
            required: true,
            message: "Please input your new password",
            trigger: ["input"],
          },
          {
            validator: validatePasswordDifferent,
            message: "Password should be different",
            trigger: "input",
          },
        ],
        repeatPassword: [
          {
            required: true,
            message: "Repeat your new password ",
            trigger: ["input", "blur"],
          },
          {
            validator: validatePasswordStartWith,
            message: "Password is not same",
            trigger: "input",
          },
          {
            validator: validatePasswordSame,
            message: "Password is not same",
            trigger: ["blur", "password-input"],
          },
        ],
      },
      updateContactInfo: async () => {
        formRef.value.validate(async (errors) => {
          if (!errors) {
            await store.dispatch("app/lockUI");
            const currentVersion = userRef.value._version;
            await store.dispatch("user/updateContactInfo", {
              ...userRef.value,
              phoneNumber: COUNTRY_CODE + userRef.value.phoneNumber,
            });
            if (userRef.value._version > currentVersion) {
              message.success("Information updated");
            } else {
              message.error("Error, updating information. Please, try again");
            }
            await store.dispatch("app/unlockUI");
          }
        });
      },
      updateAddresses: async () => {
        await store.dispatch("app/lockUI");
        const currentVersion = userRef.value._version;
        await store.dispatch("user/updateUserAddresses", {
          userId: userRef.value.id,
          addresses: userRef.value.addresses,
          version: userRef.value._version,
        });
        if (userRef.value._version > currentVersion) {
          message.success("Information updated");
        } else {
          message.error("Error, updating information. Please, try again");
        }
        await store.dispatch("app/unlockUI");
      },
      updatePayoutInfo: async () => {
        formRefPayoutProfileRef.value.validate(async (errors) => {
          if (!errors) {
            await store.dispatch("app/lockUI");
            const currentVersion = userRef.value._version;
            try {
              await store.dispatch(
                "user/updatePayoutProfile",
                userRef.value.payoutProfile
              );
              if (userRef.value._version > currentVersion) {
                currentAddressInPayoutRef.value = userRef.value.payoutProfile.address.id;
                message.success("Information updated");
              } else {
                throw new Error("Error updating info");
              }
            } catch (error) {
              console.error(error);
              message.error("Error, updating information. Please, try again");
            }
            await store.dispatch("app/unlockUI");
          }
        });
      },
      changePassword: async () => {
        console.debug("formPasswordRef", formPasswordRef.value);
        formPasswordRef.value.validate(async (errors) => {
          if (!errors) {
            await store.dispatch("app/lockUI");
            Auth.currentAuthenticatedUser()
              .then((user) => {
                return Auth.changePassword(
                  user,
                  formPassword.value.currentPassword,
                  formPassword.value.newPassword
                );
              })
              .then((data) => {
                console.log("data", data);
                message.success("Password changed");
                formPassword.value = {
                  currentPassword: null,
                  newPassword: null,
                  repeatPassword: null,
                };
              })
              .catch((error) => {
                console.error(error);
                message.error("Error, updating information. Please, try again");
              })
              .finally(() => store.dispatch("app/unlockUI"));
          }
        });
      },
    };
  },
});
</script>

<style scoped>
.n-grid {
  text-align: left;
}
</style>
