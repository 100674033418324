<template>
  <n-list
    ><n-list-item v-for="option in options" :key="option.value">
      <n-icon><ItemIcon /></n-icon>
      <template #suffix v-if="deleteFunction && !option.options.hideDeleteButton">
        <n-button
          size="tiny"
          :bordered="false"
          @click="deleteOption(option)"
          :disabled="disabled"
          ><template #icon>
            <n-icon>
              <DeleteIcon />
            </n-icon> </template
        ></n-button>
      </template>
      {{ option.label }}
    </n-list-item>
  </n-list>
</template>
<style lang="scss" scoped>
:deep(.n-list-item__main) {
  text-align: left;
}
</style>

<script>
import { defineComponent, ref, watch } from "vue";
import { TrashBinOutline as DeleteIcon, MapOutline as ItemIcon } from "@vicons/ionicons5";
import { useDialog, NList, NListItem, NButton, NIcon } from "naive-ui";

export default defineComponent({
  name: "RadioGroup",
  components: { DeleteIcon, NList, NListItem, NButton, NIcon, ItemIcon },
  props: {
    value: { type: [Object, String, Number] },
    options: { type: Array },
    deleteFunction: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const checkedValueRef = ref(props.value);
    const dialog = useDialog();

    watch(checkedValueRef, (checkedValueRef) => {
      emit("update:value", checkedValueRef);
    });
    watch(
      () => props.value,
      (newValue) => {
        checkedValueRef.value = newValue;
      }
    );
    return {
      checkedValue: checkedValueRef,
      deleteOption: (option) => {
        dialog.warning({
          title: "Confirm",
          content: `Delete address: ${option.label}?`,
          positiveText: "Yes",
          negativeText: "No",
          onPositiveClick: () => {
            props.deleteFunction(option);
          },
        });
      },
    };
  },
});
</script>
